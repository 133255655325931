<template>
    <div class="wrapper">
        <section class="section">
            <Banner />
            <h1 class="section__title">Играть в онлайн казино Пин Ап: официальный сайт Pin Up</h1>
            <Slots />
            <div class="infos">
                <Information :data="item" v-for="(item, index) of info" :key="index" />
            </div>
        </section>
        <aside class="aside">
            <Sidebar />
            <Buttons />
        </aside>


    </div>
</template>
<script>
import Banner from './Section/Banner.vue';
import Slots from './Section/Slots.vue';
import Information from './Section/Information.vue';
import Sidebar from './Section/Sidebar.vue';
import Buttons from './Section/Buttons.vue';
export default {
    name: 'SectionLanding',
    components: {
        Banner,
        Slots,
        Information,
        Sidebar,
        Buttons,
    },
    data() {
        return {
            info: [{
                title: 'Что такое казино Pin Up?',
                text: [
                    `Pin Up Casino - игровая платформа, которая уже давно зарекомендовала себя как надежный и профессиональный клуб для онлайн-гемблинга. У клуба есть актуальное на сегодня зеркало, которое доступно для всех пользователей. Найти его можно, например, через поисковик Яндекс, используя фразу "pin up casino зеркало".`,
                    `Чтобы начать играть в клубе, нужно зарегистрироваться и выполнить pin up вход в личный кабинет. Для регистрации необходимо заполнить несколько полей и подтвердить свой аккаунт по электронной почте. Процесс регистрации занимает не более 5 минут.`,
                    `В клубе Pin Up Casino представлен широкий выбор игр: игровые автоматы за деньги, рулетки, баккара, блэкджек и другие популярные азартные развлечения. Все игры доступны в демо-режиме, что позволяет игрокам протестировать их бесплатно перед игрой на реальные деньги.`,
                    `Каждый новый игрок может получить бонусы от клуба. Для этого нужно выполнить pin up регистрацию и внести депозит на свой игровой счет. В клубе также регулярно проводятся акции и турниры с призовым фондом.`,
                    `Для доступа к сайту клуба, если основной домен заблокирован, можно использовать pin up зеркало актуальное. Также на сайте клуба есть раздел с ответами на часто задаваемые вопросы и онлайн-чат с операторами поддержки, готовыми ответить на любые вопросы пользователей.`,
                    `Если выигрыш в клубе Pin Up превышает 300 рублей, можно запросить вывод средств. Вывод доступен после выполнения нескольких условий, включая отсутствие активных бонус-опций, верификацию данных и соблюдение правил честной игры. При запросе на вывод более 300000 рублей в день сумма будет разделена на несколько транзакций.`,
                    `Итак, если вы ищете надежный онлайн-клуб для игры в игровые автоматы, то клуб Pin Up Casino - отличный выбор. Скачать Pin Up казино не нужно, все игры доступны в браузере на сайте клуба. Просто выполните pin up вход и наслаждайтесь игрой!`
                ]
            },
            {
                title: 'Какие возможности предоставляет онлайн казино Pin Up?',
                text: [
                    `Онлайн казино Pin Up Casino было создано компанией Carletta Ltd, базирующейся на Кипре, в начале 2016 года. Оператор уже успел заявить о себе в других казино, как в русскоязычных, так и в европейских заведениях. Однако, Pin Up Casino было разработано с нуля, и его уникальный дизайн игровой платформы это подтверждает.`,
                    `Официальный сайт Pin Up Casino имеет удобный интерфейс, который поделен на разделы. Основная панель навигации находится по бокам страницы, где игроки могут увидеть лотереи, турниры, разработчиков игровых автоматов и многое другое. Верхняя часть страницы предназначена для регистрации и входа, а также представляет различные азартные развлечения.`,
                    `Сайт поддерживает несколько языков, что позволяет гемблерам из разных стран играть на нем. При регистрации игрок может выбрать российские рубли, украинские гривны, евро и доллары, казахстанские тенге, турецкие лиры и другие региональные европейские валюты.`,
                    `Pin Up Casino предлагает своим посетителям широкий выбор игр, включая слоты, живых дилеров и настольные игры, игровые автоматы пин ап. Он также предоставляет возможность делать ставки на виртуальный спорт и ТВ-игры, что является новинкой в русскоязычном интернет-гемблинге. Каждое приложение можно запустить в бесплатном демо-режиме, чтобы попробовать игру без риска потери денег.`
                ]
            },
            {
                title: 'Кто поставляет игровые автоматы в казино Pin Up?',
                text: [
                    `На официальном сайте казино Пин Ап доступны более 1500 симуляторов от 40 провайдеров, среди которых Microgaming, Playson, Pragmatic Play, ELK Studios, BetSoft, Evoplay, Belatra, NetEnt, NoLimit City, Iron Dog, Play’n Go и другие. Все слоты удобно сортируются по популярности и дате выпуска.`,
                    `В Пин Ап казино вход можно осуществить в любое удобное время, и выбрать симуляторы с различной механикой и характеристиками. Здесь можно найти как классические игры с тремя барабанами, так и новые симуляторы с нестандартной механикой, а также игровые автоматы с накопительным призовым фондом, в которых можно выиграть до 10 миллионов евро.`,
                    `Помимо игровых автоматов, на сайте онлайн казино Pin Up также представлен раздел ставок на спорт, а также раздел с живыми дилерами. В live-сессиях можно поиграть в рулетку, блэкджек, баккара и покер, а также конкурировать за солидные призы с другими гостями и реальными крупье.`,
                    `Чтобы начать играть в казино Пин Ап, нужно зарегистрироваться на официальном сайте или воспользоваться актуальным зеркалом. После входа на сайт доступны все игры и бонусы, а также различные способы пополнения и вывода денег.`
                ]
            },
            {
                title: 'Как сделать первый депозит в Пин Ап казино',
                text: [
                    `Для начала игры в Пин Ап казино необходимо зарегистрироваться на официальном сайте или мобильной версии, используя мобильный номер или адрес электронной почты. После регистрации, чтобы получить доступ к играм на деньги, игроку необходимо пройти верификацию, предоставив службе поддержки копию паспорта.`,
                    `После верификации гемблеры могут вносить депозиты на свой счет, используя различные методы оплаты: банковские карты, электронные кошельки (Киви, Вебмани, Яндекс.Деньги) и сервисы моментальных переводов. Минимальный депозит в Пин Ап казино составляет 50 рублей.`,
                    `Чтобы пополнить счет, игроку нужно выбрать платежную систему, указать сумму перевода и подтвердить транзакцию. После этого на мобильный номер или адрес электронной почты придет информация о проведенной операции и чек, который нужно оплатить.`,
                    `После успешного перевода средств, статус депозита обновится в личном кабинете на официальном сайте или мобильной версии Пин Ап казино. Теперь гемблеры могут наслаждаться играми на деньги и выигрывать реальные призы. Не забывайте использовать актуальное зеркало Пин Ап казино для бесперебойного доступа к играм.`
                ]
            },
            {
                title: 'Возможности для новичков в онлайн-казино Пин Ап',
                text: [
                    `Представляем эксклюзивные возможности для новичков в онлайн-казино Пин Ап. Оператор осознает, что первые шаги в мире азартных игр могут быть сложными, поэтому предлагает специальную программу поддержки для новых клиентов.`,
                    `Программа состоит из нескольких блоков. Во-первых, есть возможность получить бездепозитное поощрение, которое не всегда доступно. Такое предложение можно найти на главной странице казино. Благодаря этому подарку новичок получает виртуальные рубли и бесплатные вращения на игровых автоматах.`,
                    `Кроме того, каждый новый игрок может получить приветственный пакет, который может составить до 25000 рублей. Начните играть с депозита в 2000 рублей, после чего в разделе акций можно получить бонус на 150% от своего депозита.`,
                    `Еще одним преимуществом для новых клиентов являются фриспины. Каждый новый зарегистрированный игрок может получить до 300 бесплатных вращений на неделю. Фриспины начисляются равными частями каждые сутки.`,
                    `Наконец, после того, как игрок потратит 5000 рублей на игровых автоматах, он получит шанс на эксклюзивную лотерею. Такой ивент исключает проигрыш, поэтому каждый посетитель обязательно уйдет с подарком.`,
                    `Если у вас возникнут вопросы о правилах казино, акциях и бонусах, вы можете связаться со службой поддержки или подписаться на рассылку электронной почты казино Пин Ап. Воспользуйтесь нашими эксклюзивными предложениями и наслаждайтесь игрой вместе с нами!`
                ]
            },
            {
                title: 'Как вывести выигрыш?',
                text: [
                    `Выигрыши в клубе Pin Up могут быть выведены при выполнении определенных условий. Для начала, необходимо набрать минимальную сумму в 300 рублей, после чего открыть запрос на вывод. Однако, помимо этого, игрок должен соблюдать некоторые требования со стороны администрации.`,
                    `Первым условием является отсутствие активных бонус-опций на аккаунте игрока. Если на момент запроса на вывод есть незавершенные бонусы, то средства на аккаунте будут заморожены до их окончания или до истечения 72-часового срока. Вторым требованием является верификация данных игрока. Для этого необходимо предоставить сканы паспорта и дождаться проверки информации со стороны сотрудников клуба Pin Up, что может занять около 3-4 дней.`,
                    `Третье условие - это суточный лимит на вывод. Если выигрыш с одного вращения превышает 500000 рублей, то выводить его можно только частями, не превышающими 300000 рублей в день. И последнее, но не менее важное условие - это соблюдение правил честной игры. Если игрок нарушает правила клуба Pin Up, например, создает второй аккаунт или пытается взломать автоматы, то администрация вправе отказать в выплате выигрыша и заблокировать профиль игрока.`,
                    `Теперь, когда вы знаете все условия вывода выигрышей в клубе Pin Up, можете спокойно наслаждаться игровым процессом и надеяться на удачу! А если вам нужно скачать приложение или зайти на сайт клуба Pin Up, то используйте одно из актуальных зеркал и наслаждайтесь любимыми игровыми автоматами за деньги или бесплатно.`
                ]
            }
            ]
        }
    },

}
</script>
<style lang="scss" scoped>
.wrapper {
    display: flex;
    padding: 0 51px 0 230px;
    background: url(@/assets/img/seat_woman.png) right bottom 20% no-repeat, url(@/assets/img/stay_woman.png) left top no-repeat;
}

.section {
    padding: 0 83px 0 0;
    max-width: 1140px;
    box-sizing: content-box;

    &__title {
        font-family: 'PT Sans';
        font-weight: 700;
        font-size: 30px;
        color: #FFFFFF;
        margin: 40px 0;
    }

}

.infos {
    padding: 30px 0 90px;
}

.aside {
    max-width: 276px;
    margin-left: auto;
}

@media screen and (max-width:1540px) {
    .section {
        max-width: 960px;
        padding-right: 45px;
    }

    .wrapper {
        padding: 0 30px;
        background: url(http://localhost:8080/img/seat_woman.04b3cc42.png) right bottom 20% no-repeat;
    }
}

@media screen and (max-width:1200px) {
    .aside {
        display: none;
    }
}

@media screen and (max-width:992px) {
    .section {
        padding: 0;
    }
}

@media screen and (max-width:480px) {
    .section{
        &__title{
            font-size: 17px;
            margin: 20px 0;
        }
    }
    .infos{
        padding: 30px 0;
        background: #303131d4;
    }
}

</style>